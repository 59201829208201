////////////////////////////////////////////////////////////////////////////////
@function get-breakpoints($key: "md") {
    @return map-get($container-max-widths, $key);
}
// max-width: get-breakpoints("xl");
////////////////////////////////////////////////////////////////////////
// transitions extended versions
%trans-all {
	transition: all $trans-spd $trans-kind;
}
%trans-all-fast {
	transition: all $trans-spd-fast $trans-kind;
}


////////////////////////////////////////////////////////////////////////
//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}



////////////////////////////////////////////////////////////////////////
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}





////////////////////////////////////////////////////////////////////////////////
/*
	.site-header {
    	z-index: z('site-header');
	}
*/
@function z($name) {
    @if index($z-indexes, $name) {
        @return (length($z-indexes) - index($z-indexes, $name)) + 1;
    } @else {
        @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
        @return null;
    }
}
$z-indexes: (
    "navigation",
    "overlay",
    "wrapper",
    "header-sticky"
);



////////////////////////////////////////////////////////////////////////
/*
	div {
		@include responsive-ratio(16,9);
		@include responsive-ratio(16,9, true);
	}
*/
@mixin responsive-ratio($x,$y, $pseudo: false) {
	$padding: unquote( ( $y / $x ) * 100 + '%' );
	@if $pseudo {
		&:before {
			@include pseudo($pos: relative);
			width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}
}





////////////////////////////////////////////////////////////////////////
//
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
	@include pseudo($pos: $position);
	width: 0;
	height: 0;
	@if $round {
		border-radius: 3px;
	}
	@if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
		margin-top: 0 - round( $size / 2.5 );
	} @else if $direction == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
		margin-bottom: 0 - round( $size / 2.5 );
	} @else if $direction == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		margin-right: -$size;
	} @else if  $direction == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		margin-left: -$size;
	}
}





////////////////////////////////////////////////////////////////////////
// @include hardware($backface: true, $perspective: 1000);
//
@mixin hardware($backface: true, $perspective: 1000) {
	@if $backface {
		backface-visibility: hidden;
	}
	perspective: $perspective;
}





////////////////////////////////////////////////////////////////////////
// @include pos(fixed, 0, 0, 0, 0, false, true) {
//
@mixin pos($pos, $t, $r, $b, $l, $z: false, $hardware: true) {
	@if $pos == fixed and $hardware { @include hardware; }
	@if $pos { position: $pos; }
	@if $t { top: $t; }
	@if $r { right: $r; }
	@if $b { bottom: $b; }
	@if $l { left: $l; }
	@if $z { z-index: $z; }
}





////////////////////////////////////////////////////////////////////////
// Padding and Margin Generation
/*
	$properties:
		padding
		padding-top
		padding-right
		padding-bottom
		padding-left
		margin
		margin-top
		margin-right
		margin-bottom
		margin-left;
	Output:
		.padding-top-0: 0px !important;
		.padding-top-1: 10px !important;
		.margin-left-19 { margin-left: 190px !important; }
		.margin-left-20 { margin-left: 200px !important; }
*/

$properties: padding padding-top padding-right padding-bottom padding-left margin margin-top margin-right margin-bottom margin-left;
@each $property in $properties {
	$i: index($properties, $property);
	@for $n from 0 through 10 {
		$value: 10px * $n;
		.#{$property}-#{$n} {
			#{$property}: $value !important;
		}
	}
}






////////////////////////////////////////////////////////////////////////
// use it
/*
@include selection {
    color: white;
    background: black;
}
*/
// define it
@mixin selection {
	::-moz-selection { @content; }
	::selection { @content; }
}





////////////////////////////////////////////////////////////////////////
/// https://css-tricks.com/snippets/sass/custom-scrollbars-mixin/
/// @include scrollbars(.5em, #ffffff, #ff00ff);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  	// For Google Chrome
	::-webkit-scrollbar {
		width:  $size;
		height: $size;
	}

	::-webkit-scrollbar-thumb {
		background: $foreground-color;
	}

	::-webkit-scrollbar-track {
		background: $background-color;
	}
	// For Internet Explorer
	body {
		scrollbar-face-color: $foreground-color;
		scrollbar-track-color: $background-color;
	}
}





////////////////////////////////////////////////////////////////////////
/*
.faded-text {
	@include opacity(0.8);
}
*/
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

////////////////////////////////////////////////////////////////////////
/*
.container-with-floated-children {
	@extend %clearfix;
}
*/
%clearfix {
	*zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}
.clearfix {
	@extend %clearfix;
}





////////////////////////////////////////////////////////////////////////
/// set to no breaks
h1, h2, h3, h4, h5, h6, p, blockquote {
	hyphens: none;
}




////////////////////////////////////////////////////////////////////////
/// set to uppercase
%upper {
	text-transform: uppercase;
}
.upper, .caps {
	@extend %upper;
}





////////////////////////////////////////////////////////////////////////
/// set to thin
%thin {
	font-weight: lighter;
}
.thin {
	@extend %thin;
}




////////////////////////////////////////////////////////////////////////
/// set to fat
%fat {
	font-weight: bolder;
}
.fat {
	@extend %fat;
}





////////////////////////////////////////////////////////////////////////
/// hide
%hidden {
	//display: none;
	visibility: hidden;
}
.hidden {
	@extend %hidden;
}





////////////////////////////////////////////////////////////////////////
/// show
%shown {
	visibility: visible;
}
.shown {
	@extend %shown;
}





////////////////////////////////////////////////////////////////////////
@mixin visible($state: 'block') {
	display: unquote($state);
	visibility: visible;
}





////////////////////////////////////////////////////////////////////////
/// some classes + extends
%pointer-events {
	pointer-events: all !important;
}
.pointer-events, .enableClick {
	@extend %pointer-events;
}





////////////////////////////////////////////////////////////////////////
%disable-pointer-events {
	pointer-events: none !important;
}
.disable-pointer-events, .disableClick {
	@extend %disable-pointer-events;
}





////////////////////////////////////////////////////////////////////////
%pointer {
	cursor: pointer;
}
.pointer {
	@extend %pointer;
}





////////////////////////////////////////////////////////////////////////
%selectNo {
	-webkit-touch-callout: none !important;
	user-select: none !important;
}
.noSelect,.noSelect *,
.disableSelect, .disableSelect * {
	@extend %selectNo;
}





////////////////////////////////////////////////////////////////////////
%selectYes {
	-webkit-touch-callout: auto !important;
	user-select: auto !important;
}
.selectable,.selectable *,
.enableSelect, .enableSelect * {
	@extend %selectYes;
}





////////////////////////////////////////////////////////////////////////
/*
	.button{
    	@include linearGradient(#cccccc, #666666);
	}
*/
@mixin linearGradient($top, $bottom){
	background: $top; /* Old browsers */
	background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
	background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}



////////////////////////////////////////////////////////////////////////
// @include centerer();
//
@mixin centerer($horizontal: true, $vertical: true) {
	position: absolute;
	@if ($horizontal and $vertical) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if ($horizontal) {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if ($vertical) {
		top: 50%;
		transform: translate(0, -50%);
	}
}

