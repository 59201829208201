@charset "utf-8";

@import '__settings';
@import '__mixins';
@import '__mixins_color';
@import '___bootstrap/bootstrap';
/*
@import '___owlcarousel/owl.carousel';
@import '___owlcarousel/owl.theme.sos';
*/
// https://codepen.io/jonnitto/pen/OVmvPB
//@import '___form-material-design/form-material-design.scss';

// https://jonsuh.com/hamburgers/
@import "./node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";


/* ////////////////////////////////////////////////////////////////////////
// two different Animated on Scroll

http://jjcosgrove.github.io/jquery-aniview/
@import '_aniview';

https://github.com/michalsnik/aos
https://github.com/michalsnik/aos#animations
@import "./node_modules/aos/src/sass/aos.scss";
*/


@import "__flickity/flickity-theme-sos.scss";

$aos-distance: 15px;
@import "./node_modules/aos/src/sass/aos.scss";



////////////////////////////////////////////////////////////////////////////////
html, body, * {}

html, body {
}


body {
	padding-top: 70px;
	@include media-breakpoint-up(lg) {
		padding-top: 100px;
	}
}

html {
	scroll-behavior: smooth;
	@include scrollbars(1rem, $scrollbar-color-txt, $scrollbar-color-bg);
}

a {
	color: $white;
	text-decoration: underline;
	&:hover {
		color: $white;
		text-decoration: none;
	}
	&.line-no {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

}
.upper {
	text-transform: uppercase;
}
.btn-link {
	a {
		text-decoration: none !important;
		color: inherit !important;
	}
}
.dimmed {
	opacity: 0.4;
}


.embed-container {
	position: relative;
	overflow: hidden;
	&.embed-responsive-16by9{
		padding-bottom: 56.25%; // 16:9
	}
	&.embed-responsive-21by9{
		padding-bottom: 42.85%; // 21:9
	}
	&.embed-responsive-239by1 {
		//padding-bottom: 41.84%; // 2.39:1
		padding-bottom: 41.84%; // 2.39:1
	}
	&.embed-responsive-2by1 {
		//padding-bottom: 41.84%; // 2.39:1
		padding-bottom: 50%; // 2.39:1
	}
	&.embed-responsive-home {
		padding-bottom: 41.84%; // 2.39:1
	}
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



.navbar {
	&.black {
		background-color: #000000;
	}

	.navbar-brand {
		img {
			width: 150px;
			margin: 20px 0;
		}
	}
	a {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}
}

hr {
	margin: 0 0 5px 0;
	padding: 0;
	background-color: $white;
	height: 1px;
	border: 0px solid #fff;
}


.spacer {
	height: 50px;
	&.double {
		height: 100px;
	}
	&.half {
		height: 50px;
	}
	&.quarter {
		height: 25px;
	}
}




.content {
	img {
		width: 100%;
		height: auto;
	}
	.arrow_prev__next {
		img {
			width: 30px;
			height: 30px;
		}
		&.article__previous {
			margin-right: 20px;
		}
	}
	.portrait {
/*
		float:left;
		width: 150px;
		padding-right: 20px;
*/
	}
}





.thumbs {
	img {
		width: 100%;
		height: auto;
	}
	.thumb {
		padding-right: 0;
		padding-left: 0;
		position: relative;
		overflow: hidden;

		&:hover {
			.image {
				transform: scale(1.05);
			}
			.title {
				h3 {
					opacity: 1;
				}
			}
		}
		.image {
			@extend %trans-all-fast;
			position: relative;
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			padding-bottom: 56.25%;
			margin: 15px;
		}
		.title {
			position: absolute;
			top: 0px;
			z-index: 1;
			padding: 0 0 10px 20px;
			h3 {
				@extend %trans-all-fast;
				color: $black;
				@extend .dimmed;
			}
			&.title-light {
				h3 {
					color: $white;
				}
			}
		}
	}
}

.names {
	table {
		width: 100%;
		display: table;
	}
}
