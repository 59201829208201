// ----------------------------------------------------------------------------------------
//@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

// colors
//
body {

}

$white:						#FFFFFF;
$black:						#000000;

$color-white:				$white;
$color-black:				$black;

/*
$color-bg-sections:			$white;
$color-bg-cube_faces:		$white;
*/

$scrollbar-color-bg:		$white;
$scrollbar-color-txt:		$black;
/*

$navigation-color-bg:		$black;
$navigation-color-txt:		$white;
$navigation-height:			100px;
*/





// ----------------------------------------------------------------------------------------



// Bootstrap variable - overrides


$btn-border-width:            0;

/*
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
*/
$dark:          #000000;


$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  (
    (239 1),
    (21 9),
    (21 7),
    (16 9),
    (4 3),
    (1 1),
  ),
  $embed-responsive-aspect-ratios
);


$link-color:                              rgba($white, .5);
$link-decoration:                         none;
$link-hover-color:                        rgba($white, 1);
$link-hover-decoration:                   underline;

$navbar-brand-height:               100;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;
$navbar-nav-link-padding-x:         1rem;

$navbar-dark-color:                 rgba($white, .5) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;





$enable-responsive-font-sizes:                false;



// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    20: 20%,
    25: 25%,
    30: 30%,
    40: 40%,
    45: 45%,
    50: 50%,
    55: 55%,
    60: 60%,
    70: 70%,
    75: 75%,
	80: 80%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$grid-gutter-width:          	30px;

$font-family-sans-serif:		'Signika', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$body-bg:                   	#1f1f1f;
$body-color:					$white;

$font-size-base:				1.0rem;		// Assumes the browser default, typically `16px`
$font-weight-base:				300;		// 300,400,500,700,900
$line-height-base:				1.5;

$headings-font-weight:        	600;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                $font-size-base;

$border-radius:               	0;
$border-radius-lg:            	0;
$border-radius-sm:            	0;


// ----------------------------------------------------------------------------------------
// Settings 'transitions' extended versions, see mixins.scss
/*
	%trans-all
	%trans-all-fast
*/
// ==================================================
$trans-spd: 		400ms !default;
$trans-spd-fast:	400ms / 1.5 !default;
$trans-kind:		ease-in-out !default;
$trans-kind-all:	$trans-kind !default;

// Settings hamburger these are overriding hamburger.scss
// ==================================================
$hamburger-padding-x           : 15px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 30px;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-color         : #fff;
$hamburger-layer-border-radius : 0px;
$hamburger-hover-opacity       : 0.7;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false;
$hamburger-hover-filter       : opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;
